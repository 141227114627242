import CryptoJS from 'crypto-js';

export const encryptPlaintext = (plaintext) => {
    var iv = CryptoJS.lib.WordArray.random(16);

    // Convert the key and IV to WordArray objects
    var keyBytes = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_DECRYPT_KEY);
    var ivBytes = CryptoJS.enc.Hex.parse(iv.toString());

    // Perform the encryption using AES-CBC mode
    var encrypted = CryptoJS.AES.encrypt(plaintext, keyBytes, {
        iv: ivBytes,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });

    // Combine the IV and ciphertext into a single string
    return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
}