import axios from 'axios';
import { reactLocalStorage } from 'reactjs-localstorage';
import Swal from 'sweetalert2';

let headers = {

};

const devEnvironmentVariables = process.env.REACT_APP_API_BASE_URL_DEV;

const prodEnvironmentVariables = process.env.REACT_APP_API_BASE_URL_PROD;


// const BACKEND_URL = Boolean(process.env.REACT_APP_DEBUG) ?  devEnvironmentVariables : prodEnvironmentVariables;
// const BACKEND_URL = 'https://revarc-api.aventusinformatics.com/api'
// const BACKEND_URL = devEnvironmentVariables
// const BACKEND_URL = 'http://192.168.18.182:5000/api'
// const BACKEND_URL = 'https://api.db-revarc.com/api'


const domain = window.location.origin;

let BACKEND_URL = 'https://revarc-api.aventusinformatics.com/api'

switch (domain) {
  case 'https://trizettoprovider.claimbridgehealth.com':
    BACKEND_URL = 'https://trizettoprovider-api.claimbridgehealth.com/api'
    break
  case 'https://uat-trizettoprovider.claimbridgehealth.com':
    BACKEND_URL = 'https://uat-api-trizettoprovider.claimbridgehealth.com/api'
    break
  case 'https://revarc.aventusinformatics.com':
    BACKEND_URL = 'https://revarc-api.aventusinformatics.com/api'
    break

  case 'https://db-revarc.com':
    BACKEND_URL = 'https://api.db-revarc.com/api'
    break

  case 'https://uat.db-revarc.com':
    BACKEND_URL = 'https://uatapi.db-revarc.com/api'
    break

  case 'https://frontend.db-revarc.com':
    BACKEND_URL = 'https://test-api.db-revarc.com/api'
    break

  case 'https://training.db-revarc.com':
    BACKEND_URL = 'https://training.api.db-revarc.com/api'
    break

  case 'https://demo.db-revarc.com':
    BACKEND_URL = 'https://demoapi.db-revarc.com/api'
    break

  case 'https://clrevarc.aventusinformatics.com':
    BACKEND_URL = 'https://clrevarc-api.aventusinformatics.com/api'
    break
  case 'https://poc.claimbridgehealth.com':
    BACKEND_URL = 'https://poc-api.claimbridgehealth.com/api'
    break


  case 'http://localhost:3000/':
    BACKEND_URL = 'https://revarc-api.aventusinformatics.com/api'
    // BACKEND_URL = 'https://training.api.db-revarc.com/api'
    break


  default:
    BACKEND_URL = 'https://revarc-api.aventusinformatics.com/api'
    // BACKEND_URL = 'https://training.api.db-revarc.com/api'
    break
}

// BACKEND_URL = 'http://192.168.0.178:8000/api'


const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
  headers,
});






axiosInstance.interceptors.request.use(
  async (config) => {
    const token = reactLocalStorage.get('token', '');
    const user_type = reactLocalStorage.get('user_type', '');
    const user_type_id = reactLocalStorage.get('user_type_id', '');
    if (token && token != 'undefined') {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (user_type) {
      config.params = {
        ...config.params,
        user_type: user_type,
        user_type_id: user_type_id
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      resolve(response);
    }),
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (error.response.status === 403 && window.location.pathname !== '/') {
      window.location.replace("/unauthorized");
    }
    else if (error.response.status === 401 && window.location.pathname !== '/') {

      Swal.fire({
        icon: 'error',
        confirmButtonColor: '#1ba1a6',
        title: 'Session Timeout',
        customClass: {
          title: 'swal-titleText'
        }
        // text: 'Session',
      }).then((result) => {


        localStorage.clear();
        window.location = "/";

      })

    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  },
);

export default axiosInstance;